import React from "react"

import {
  MdMailOutline as Mail,
} from "react-icons/md"

export default function info() {
    return (
        <div className="container">
      <div className="my-5"></div>
      <div className="display-3" style={{ color: "#FF8A00" }}>
        <span style = {{color : "white"}}>This is</span> <br />
        Blank<span style= {{animation: "blinker 2s linear infinite"}}>_</span> Radio.
      </div>
      <div className="h1 code mt-4 mb-3">_</div>
      <div className="text-muted mx-5 my-4 h3 text-justify info">
      <br/>
        We are here to bring you a new type of radio. <br/> 
        Coming soon!
      </div>
      <div className="h1 code mt-2 mb-3">_</div>
      <div className="h1 mt-5">
        <a className="mr-5 icon" href="mailto:info@blankradio.net">
          <Mail />
        </a>
      </div>
    </div>
    )
}